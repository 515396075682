'use strict'
import {
  // 组件部分
  ElButton,
  ElCarousel,
  ElCarouselItem,
  ElPagination,
  ElIcon,

  // 插件部分
  ElMessage, ElInfiniteScroll, ElMessageBox, ElNotification
} from 'element-plus'

export default function elementUICop (app) {
  const components = [ElButton, ElCarouselItem, ElCarousel, ElPagination, ElIcon]
  // 引入的全局element-plus插件
  const plugins = [
    ElInfiniteScroll,
    ElMessage,
    ElMessageBox,
    ElNotification
  ]
  components.forEach(el => {
    app.component(el.name, el)
  })

  plugins.forEach(plugin => {
    app.use(plugin)
  })
}
