'use strict'
import axios from 'axios'
import config from './config'
import { ElMessage } from 'element-plus'
import store from '@/store'
// const store = useStore()
// console.log('🚀 ~ file: https.js ~ line 7 ~ store', store)

// const { HEADER_AUTHORIZATION } = require('@/env')

const service = axios.create({
  baseURL: `${config.hostUrl + config.apiPreFix}`,
  timeout: 30000
})

service.baseUrl = `${config.hostUrl + config.apiPreFix}`
service.hostUrl = config.hostUrl
/* request拦截 */
service.interceptors.request.use(config => {
  // config.cancelToken = new axios.CancelToken(cancel => {
  //   store.commit('apiPost/CANCE_LIST', { u: config.url, f: cancel })
  // })

  // config.headers.Authorization = `Bearer ${HEADER_AUTHORIZATION}`
  config.headers['Content-Type'] = 'application/json'

  return config
}, error => {
  error._FROM = 'REQUEST'
  return Promise.reject(error)
})

/* response拦截 */
service.interceptors.response.use(
  response => response,
  error => {
    error._FROM = 'RESPONSE'
    if (error.message.includes('timeout')) {
      error._STATUS = 416
    } else {
      error._STATUS = error.response.status
      error._MSG = error.response.statusText
    }
    return Promise.reject(error)
  })

export const post = service.postCall = (url, reqData, cbs, errHandle = true) => {
  return new Promise((resolve, reject) => {
    service.post(url, reqData).then(async response => {
      cbs && cbs()
      resolve(response)
    }).catch(error => {
      cbs && cbs()
      if (!errHandle) {
        reject(error)
      }
      handleError(error)
    })
  })
}

export const get = (url, reqData, cbs, errHandle = true) => {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params: reqData
    }).then(async response => {
      cbs && cbs()
      resolve(response)
    }).catch(err => {
      cbs && cbs()
      if (!errHandle) {
        reject(err)
      }
      handleError(err)
    })
  })
}

function handleError (error) {
  // 如果跳转页面的话直接返回
  if (store.state.apiPost.isToUrl) {
    return false
  }

  // 当没有跳转页面的时候，网络请求错误
  let message = ''
  if (error._FROM === 'REQUEST') {
  } else if (error._FROM === 'RESPONSE') {
    switch (error._STATUS) {
      case 400:
        message = error._MSG || '错误的请求'
        break
      case 404:
        message = error._MSG || '地址错误'
        break
      case 415:
        message = error._MSG || '不支持的媒体类型，可能是没有传递参数字段所导致'
        break
      case 416:
        message = '链接请求超时'
        break
      case 500:
        message = error._MSG || '服务器内部错误'
        break
    }
  }

  ElMessage.warning({
    title: '提示',
    message
  })
}
