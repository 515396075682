let hostUrl = ''
if (process.env.NODE_ENV === 'development') {
  hostUrl = 'https://prd.api.yunlongtech.cn/ylwebsite'
} else {
  hostUrl = 'https://prd.api.yunlongtech.cn/ylwebsite'
}

export const apiPreFix = '/'
export default {
  hostUrl,
  apiPreFix
}
