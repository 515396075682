import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })

const routes = [
  {
    path: '/',
    name: 'Home',
    title: '关于我们',
    component: () => import('@/views/Home')
  },
  {
    path: '/news',
    name: 'News',
    title: '新闻动态',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/News')
  },
  {
    path: '/products',
    name: 'Products',
    title: '我们的产品',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Products')
  },
  {
    path: '/recruitInformation',
    name: 'RecruitInformation',
    title: '招采信息',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/RecruitInformation')
  },
  {
    path: '/party',
    name: 'Party',
    title: '企业党建',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Party')
  },
  {
    path: '/details',
    name: 'Details',
    component: () => import('@/views/Details')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()

  next()
  // const i = routes.findIndex(route => route.path === to.path)
  // if (i !== -1) {
  // } else {
  //   next('/404')
  // }
})

router.afterEach((to, from) => {
  NProgress.done()
})
export default router
